export enum ELanguagesCode {
  EN = 'en',
  RU = 'ru',
  ES = 'es'
}
export enum ELanguages {
  ENGLISH = 'ENGLISH',
  RUSSIAN = 'RUSSIAN',
  SPANISH = 'SPANISH'
}
