import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

import './styles/main.scss'
import './utils/i18n'

import { RouterProvider } from 'react-router-dom'
import { routes } from './routes'
import { HttpClientProvider } from './providers/http-client'
import AuthProvider from './providers/auth'
import Loader from "./components/loader/Loader"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <HttpClientProvider>
      <AuthProvider>
        <Suspense fallback={<Loader title="Loading" />}>
          <RouterProvider router={routes} />
        </Suspense>
      </AuthProvider>
    </HttpClientProvider>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
