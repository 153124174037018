import { createContext, useContext, ComponentType, PropsWithChildren } from 'react'
import { useCreateWalletService } from './service'
import { ICreateWalletContext } from './types'

const CreateWalletContext = createContext<ICreateWalletContext>({
  createWallet: async () => { },
})

export const CreateWalletProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useCreateWalletService()
  return <CreateWalletContext.Provider value={service}>{children}</CreateWalletContext.Provider>
}
const useCreateWalletContext = () => useContext(CreateWalletContext)

export default useCreateWalletContext
