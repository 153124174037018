import { useReducer } from 'react'
import useHttpClient from '../http-client'

import initialState from './state'
import reducer from './reducer'
import { EActions, IMerchantListParams } from './types'

import { requestCreateWallet } from './api/request-create-wallet'

import { ICreateWalletBody } from '@/pages/create-wallet-page/create-wallet/CreateWallet'

export const useWalletService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const getUserWallets = async () => {
    const params: IMerchantListParams = { limit: 10, page: 1 }
    dispatch({ type: EActions.GET })
    try {
      const { data } = await httpClient.get<any>('api/merchant/list', { params })
      dispatch({ type: EActions.GET_SUCCESS, wallets: data.data.merchants })

      if (data.data.merchants.length > 0) {
        dispatch({ type: EActions.GET_MERCHANT, merchant: data.data.merchants[0] })
      }
    } catch (error) {
      dispatch({ type: EActions.GET_ERROR })
    }
  }
  const createWallet = async (body: ICreateWalletBody) => {
    const responce = await requestCreateWallet(httpClient, body)
    dispatch({ type: EActions.GET_MERCHANT, merchant: responce.data.data })
  }
  return {
    state,
    createWallet,
    getUserWallets
  }
}
