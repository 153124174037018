import { CreateWalletState } from './types'
import { ICreateWalletBody } from '@/pages/create-wallet-page/create-wallet/CreateWallet'

export const initialState = (): CreateWalletState => ({
  merchant: {}
})

export const useCreateWalletService = () => {
  
  const createWallet = async (body: ICreateWalletBody) => {}

  return {
    createWallet
  }
}
