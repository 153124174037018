import { createBrowserRouter } from 'react-router-dom'
import { lazy } from 'react'

import { BackupProvider } from './providers/back-up'
import { BalanceProvider } from './providers/balance'
import { CreateWalletProvider } from './providers/create-wallet'
import { WalletProvider } from './providers/wallet'

import WalletGuard from './guards/wallet-guard'

import { ROUTES } from './constants/routes'

const UiKit = lazy(() => import('@/pages/ui-kit/UiKit'))
const App = lazy(() => import('@/App'))
const Wallet = lazy(() => import('@/pages/wallet-page/Wallet'))
const Staking = lazy(() => import('@/pages/staking-page/Staking'))
const Farm = lazy(() => import('@/pages/farm-page/Farm'))
const NotFound = lazy(() => import('@/pages/not-found-page/NotFound'))
const InitiaLayout = lazy(() => import('@/layouts/initial-layout/InitiaLayout'))
const UserLayout = lazy(() => import('@/layouts/user-layout/UserLayout'))
const Hero = lazy(() => import('@/pages/hero-page/hero/Hero'))
const CreateWallet = lazy(() => import('@/pages/create-wallet-page/create-wallet/CreateWallet'))
const CreateWalletReady = lazy(
  () => import('@/pages/create-wallet-page/create-wallet-ready/CreateWalletReady')
)
const BackUp = lazy(() => import('@/pages/back-up-page/back-up/BackUp'))
const BackUpPassPhrase = lazy(
  () => import('@/pages/back-up-page/back-up-pass-phrase/BackUpPassPhrase')
)
const BackUpForm = lazy(() => import('@/pages/back-up-page/back-up-form/BackUpForm'))
const WalletsList = lazy(() => import('@/pages/wallets-list-page/WalletsList'))
const AddWalletMethod = lazy(
  () => import('@/pages/add-wallet-page/add-method-page/AddWalletMethod')
)
const AddWallet = lazy(() => import('@/pages/add-wallet-page/add-wallet/AddWallet'))
const NewWallet = lazy(() => import('@/pages/add-wallet-page/new/NewWallet'))
const ImportWalletMethod = lazy(
  () => import('@/pages/import-wallet-page/import-method/ImportWalletMethod')
)
const AddExistingWallet = lazy(
  () => import('@/pages/add-wallet-page/add-existing/AddExistingWallet')
)
const ImportWallet = lazy(() => import('@/pages/import-wallet-page/import-wallet/ImportWallet'))
const ImportRecovery = lazy(
  () => import('@/pages/import-wallet-page/import-recovery/ImportRecovery')
)
const ImportPrivate = lazy(() => import('@/pages/import-wallet-page/import-private/ImportPrivate'))
const ImportCreateWallet = lazy(() => import('@/pages/import-wallet-page/import-create-wallet/ImportCreateWallet'))
const BackUpCompleted = lazy(() => import('@/pages/back-up-page/back-up-completed/BackUpCompleted'))
const RecoveryPhraseImport = lazy(
  () => import('@/pages/recovery-phrase-import-page/recovery-phrase-import/RecoveryPhraseImport')
)
const RecoveryPhraseImportForm = lazy(
  () =>
    import(
      '@/pages/recovery-phrase-import-page/recovery-phrase-import-form/RecoveryPhraseImportForm'
    )
)

export const routes = createBrowserRouter([
  {
    path: ROUTES.DEFAULT.key,
    element: (
      <WalletProvider>
        <BalanceProvider>
          <App />
        </BalanceProvider>
      </WalletProvider>
    ),
    children: [
      {
        path: ROUTES.INITIAL.key,
        element: <InitiaLayout />,
        children: [
          {
            path: ROUTES.HERO.key,
            element: <Hero />
          },
          {
            path: ROUTES.CREATE_WALLET.key,
            element: (
              <CreateWalletProvider>
                <CreateWallet />
              </CreateWalletProvider>
            )
          },
          { path: ROUTES.CREATE_WALLET_READY.key, element: <CreateWalletReady /> },
          {
            path: ROUTES.BACK_UP.key,
            element: (
              <CreateWalletProvider>
                <BackupProvider>
                  <BackUp />
                </BackupProvider>
              </CreateWalletProvider>
            ),
            children: [
              { path: ROUTES.BACK_UP_PASS_PHRASE.key, element: <BackUpPassPhrase /> },
              { path: ROUTES.BACK_UP_FORM.key, element: <BackUpForm /> },
              { path: ROUTES.BACK_UP_COMPLETED.key, element: <BackUpCompleted /> }
            ]
          },
          {
            path: ROUTES.RECOVERY_PHRASE_IMPORT.key,
            element: <RecoveryPhraseImport />,
            children: [
              {
                path: ROUTES.RECOVERY_PHRASE_IMPORT_FORM.key,
                element: <RecoveryPhraseImportForm />
              }
            ]
          },
          {
            path: ROUTES.WALLET_IMPORT.key,
            element: <ImportWallet />,
            children: [
              {
                path: ROUTES.WALLET_IMPORT_METHOD.key,
                element: <ImportWalletMethod />
              },
              {
                path: ROUTES.WALLET_IMPORT_RECOVERY.key,
                element: <ImportRecovery />
              },
              {
                path: ROUTES.WALLET_IMPORT_PRIVATE.key,
                element: <ImportPrivate />
              },
              {
                path: ROUTES.WALLET_IMPORT_CREATE.key,
                element: <ImportCreateWallet />
              }
            ]
          }
        ]
      },
      {
        path: ROUTES.UI_KIT.key,
        element: <UiKit />
      },
      {
        path: ROUTES.USER.key,
        element: (
          <WalletGuard>
            <UserLayout />
          </WalletGuard>
        ),
        children: [
          {
            path: ROUTES.WALLET.key,
            element: <Wallet />
          },
          {
            path: ROUTES.WALLET_LIST.key,
            element: <WalletsList />
          },
          {
            path: ROUTES.WALLET_ADD.key,
            element: <AddWallet />,
            children: [
              {
                path: ROUTES.WALLET_ADD_METHOD.key,
                element: <AddWalletMethod />
              },
              {
                path: ROUTES.WALLET_ADD_NEW.key,
                element: <NewWallet />
              },
              {
                path: ROUTES.WALLET_ADD_ADD_EXISTING.key,
                element: <AddExistingWallet />
              }
            ]
          },
          {
            path: ROUTES.STAKING.key,
            element: <Staking />
          },
          {
            path: ROUTES.FARM.key,
            element: <Farm />
          }
        ]
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
])
