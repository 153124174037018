import { IReducerAction } from '@/types/other/state.interface'

export enum EActions {
  HIDE_BALANCE = 'HIDE_BALANCE',
  SHOW_BALANCE = 'SHOW_BALANCE',
  SET_BALANCE = 'SET_BALANCE',
  GET = 'GET',
  GET_SUCCESS = 'GET_SUCCESS',
  GET_ERROR = 'GET_ERROR',
  GET_BALANCE = 'GET_BALANCE'
}

export interface IMerchantBalanceParams {
  typeNetwork: string
}

export interface IBalanceState {
  balance: IBalanceResponse | null
  isHidden: boolean
}

export interface IBalanceTotal {
  usd: number
  btc: number
}

export interface IWalletBalance {
  wallet: string
  balance: {
    value: string
    usd: number
  }
  currency: {
    id: string
    name: string
    nodeType: string
    title: string
    type: string
  }
}

export interface IBalanceResponse {
  balances: IWalletBalance[]
  total: IBalanceTotal
}

export interface IBalanceContext extends IBalanceState {
  hide: () => void
  show: () => void
  getAccountBalance: (merchantId: string) => void
}

export interface IBalanceActionHide extends IReducerAction<EActions.HIDE_BALANCE> {}
export interface IBalanceActionShow extends IReducerAction<EActions.SHOW_BALANCE> {}
export interface IBalanceActionGet extends IReducerAction<EActions.GET> {}
export interface IBalanceActionGetSuccess extends IReducerAction<EActions.GET_SUCCESS> {
  payload: IBalanceResponse
}
export interface IBalanceActionGetError extends IReducerAction<EActions.GET_ERROR> {}

export type Actions =
  | IBalanceActionHide
  | IBalanceActionShow
  | IBalanceActionGet
  | IBalanceActionGetError
  | IBalanceActionGetSuccess
