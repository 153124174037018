export const ROUTES = {
  DEFAULT: {
    key: '/',
    path: '/'
  },
  INITIAL: {
    key: 'initial',
    path: '/initial'
  },
  HERO: {
    key: 'hero',
    path: '/initial/hero'
  },
  CREATE_WALLET: {
    key: 'create-wallet',
    path: '/initial/create-wallet'
  },
  CREATE_WALLET_READY: {
    key: 'create-wallet-ready',
    path: '/initial/create-wallet-ready'
  },
  BACK_UP: {
    key: 'back-up',
    path: '/initial/back-up'
  },
  BACK_UP_PASS_PHRASE: {
    key: 'pass-phrase',
    path: '/initial/back-up/pass-phrase'
  },
  BACK_UP_FORM: {
    key: 'form',
    path: '/initial/back-up/form'
  },
  BACK_UP_COMPLETED: {
    key: 'completed',
    path: '/initial/back-up/completed'
  },
  RECOVERY_PHRASE_IMPORT: {
    key: 'recovery-phrase-import',
    path: '/initial/recovery-phrase-import'
  },
  RECOVERY_PHRASE_IMPORT_FORM: {
    key: 'form',
    path: '/initial/recovery-phrase-import/form'
  },
  UI_KIT: {
    key: 'ui-kit',
    path: '/ui-kit'
  },
  USER: {
    key: 'user',
    path: '/user'
  },
  WALLET: {
    key: 'wallet',
    path: '/user/wallet'
  },
  WALLET_LIST: {
    key: 'wallet/list',
    path: '/user/wallet/list'
  },
  WALLET_ADD: {
    key: 'wallet/add',
    path: '/user/wallet/add'
  },
  WALLET_ADD_METHOD: {
    key: 'method',
    path: '/user/wallet/add/method'
  },
  WALLET_ADD_NEW: {
    key: 'new',
    path: '/user/wallet/add/new'
  },
  WALLET_ADD_ADD_EXISTING: {
    key: 'add-existing',
    path: '/user/wallet/add/add-existing'
  },
  WALLET_IMPORT: {
    key: 'import',
    path: '/initial/import'
  },
  WALLET_IMPORT_METHOD: {
    key: 'method',
    path: '/initial/import/method'
  },
  WALLET_IMPORT_RECOVERY: {
    key: 'recovery',
    path: '/initial/import/recovery'
  },
  WALLET_IMPORT_PRIVATE: {
    key: 'private',
    path: '/initial/import/private'
  },
  WALLET_IMPORT_CREATE: {
    key: 'create',
    path: '/initial/import/create'
  },
  STAKING: {
    key: 'staking',
    path: '/user/staking'
  },
  FARM: {
    key: 'farm',
    path: '/user/farm'
  },

}

export const ROUTES_WITHOUT_NAV = [
  ROUTES.WALLET_LIST.path,
  ROUTES.WALLET_ADD.path,
  ROUTES.WALLET_ADD_ADD_EXISTING.path,
  ROUTES.WALLET_ADD_NEW.path,
]
