import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { ELanguages, ELanguagesCode } from '@/types/localization'

export const supportedLngs = {
  en: ELanguages.ENGLISH,
  ru: ELanguages.RUSSIAN,
  es: ELanguages.RUSSIAN
}

const defaultLng = ELanguagesCode.EN
localStorage.removeItem('i18nextLng')
const storedLng = localStorage.getItem('i18nextLng')

if (!storedLng) {
  localStorage.setItem('i18nextLng', defaultLng)
}

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLng,
    supportedLngs: Object.keys(supportedLngs),
    detection: {
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'i18nextLng'
    },
    debug: true,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
