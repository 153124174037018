import { ComponentType, createContext, PropsWithChildren } from 'react'
import { useBalanceService } from './service'
import { IBalanceContext } from './types'

export const BalanceContext = createContext<IBalanceContext>({
  isHidden: false,
  balance: null,
  hide: () => {},
  show: () => {},
  getAccountBalance: () => {}
})

export const BalanceProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const value = useBalanceService()

  return <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>
}
