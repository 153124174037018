import { useReducer } from 'react'
import { reducer } from './reducer'
import { initialState } from './state'
import { EActions, IBalanceResponse, IMerchantBalanceParams } from './types'
import useHttpClient from '../http-client'
import { IApiResponse } from '@/types/api/api.interface'

export const useBalanceService = () => {
  const [state, dispatch] = useReducer(reducer, initialState())
  const httpClient = useHttpClient()

  const hide = () => dispatch({ type: EActions.HIDE_BALANCE })

  const show = () => dispatch({ type: EActions.SHOW_BALANCE })

  const getAccountBalance = async (merchantId: string) => {
    const params: IMerchantBalanceParams = {
      typeNetwork: process.env.REACT_APP_TYPE_NETWORK as string
    }
    dispatch({ type: EActions.GET })

    try {
      const { data } = await httpClient.get<IApiResponse<IBalanceResponse>>(
        `api/merchant/${merchantId}/balance`,
        { params }
      )
      dispatch({ type: EActions.GET_SUCCESS, payload: data.data })
    } catch (error) {
      dispatch({ type: EActions.GET_ERROR })
    }
  }

  return {
    ...state,
    hide,
    show,
    getAccountBalance
  }
}
