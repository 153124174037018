import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'
import initialState from './state'
import { IWalletContext } from './types'
import { useWalletService } from './service'

const WalletContext = createContext<IWalletContext>({
  state: initialState(),
  getUserWallets: async () => {},
  createWallet: async () => {},
})

export const WalletProvider: ComponentType<PropsWithChildren> = ({ children }) => {
  const service = useWalletService()
  return <WalletContext.Provider value={service}>{children}</WalletContext.Provider>
}
const useWalletContext = () => useContext(WalletContext)

export default useWalletContext
